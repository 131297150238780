@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap");
body {
  font-family: "DM Sans", sans-serif;
}

option {
  color: black;
}

@keyframes rotateinf {
  0% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(360deg);
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 100;
  }
}

.no-text-wrap {
  text-wrap: nowrap;
}

.text-wrap {
  text-wrap: wrap;
}