.rpv-toolbar__right {
  display: none;
}

.rpv-core__viewer--dark {
  --rpv-default-layout__body-background-color: transparent;
  --rpv-default-layout__container-border-color: transparent;
  --rpv-default-layout__toolbar-background-color: rgba(255, 255, 255, 0.16);
  --rpv-default-layout__toolbar-border-bottom-color: transparent;
  --rpv-default-layout__sidebar-border-color: transparent;
  --rpv-default-layout__sidebar--opened-background-color: #fff;
  --rpv-default-layout__sidebar-headers-background-color: transparent;
  --rpv-default-layout__sidebar-content--opened-background-color: transparent;
  --rpv-default-layout__sidebar-content--opened-border-color: transparent;
  --rpv-default-layout__sidebar-content--opened-color: #fff;

  --rpv-core__asking-password-background-color: #363636;
  --rpv-core__asking-password-color: #fff;
  --rpv-core__asking-password-wrapper-border-color: #191919;
  --rpv-core__asking-password-wrapper-background-color: #191919;
  --rpv-core__button-background-color: #171717;
  --rpv-core__button-color: #fff;
  --rpv-core__doc-error-background-color: #191919;
  --rpv-core__doc-error-text-background-color: #c02323;
  --rpv-core__doc-error-text-color: #fff;
  --rpv-core__doc-loading-background-color: #191919;
  --rpv-core__full-screen-target-background-color: #1a1a1a;
  --rpv-core__inner-page-background-color: rgba(255, 255, 255, 0.05);
  --rpv-core__menu-divider-border-bottom-color: #000;
  --rpv-core__menu-item-color: #fff;
  --rpv-core__menu-item--hover-background-color: #2566e8;
  --rpv-core__menu-item--disabled-color: #5e5e5e;
  --rpv-core__minimal-button-color: #fff;
  --rpv-core__minimal-button--disabled-color: #5e5e5e;
  --rpv-core__minimal-button--hover-background-color: #191919;
  --rpv-core__minimal-button--selected-background-color: #1657bb;
  --rpv-core__modal-body-background-color: #363636;
  --rpv-core__primary-button-background-color: #2566e8;
  --rpv-core__popover-body-background-color: #363636;
  --rpv-core__popover-body-color: #fff;
  --rpv-core__progress-bar-background-color: #000;
  --rpv-core__separator-border-bottom-color: #000;
  --rpv-core__spinner-border-color: #fff;
  --rpv-core__splitter-background-color: #1a1a1a;
  --rpv-core__splitter--hover-background-color: #2566e8;
  --rpv-core__textbox-background-color: transparent;
  --rpv-core__textbox-border-color: #fff;
  --rpv-core__textbox-color: #fff;
  --rpv-core__tooltip-body-background-color: #414141;
  --rpv-core__tooltip-body-color: #fff;
}
