/* Calendar common theme */
.vc-react-datepicker-calendar {
    font-family: "DM Sans", sans-serif;
}

/* Calendar light theme */
.vc-react-datepicker-calendar.vc-datepicker-light {
    background-color: #fff;
    border: 1px solid #aeaeae;
}

.vc-react-datepicker-calendar.vc-datepicker-light .react-datepicker__header{
    background-color: #F7FAFC;
}

.vc-react-datepicker-calendar.vc-datepicker-light .react-datepicker__current-month,
.vc-react-datepicker-calendar.vc-datepicker-light .react-datepicker__day-name,
.vc-react-datepicker-calendar.vc-datepicker-light .react-datepicker__day {
    color: #2B3674;
}
/* react-datepicker__day--selected */
.vc-react-datepicker-calendar.vc-datepicker-light .react-datepicker__day--disabled{
    color: #ccc;
}

.vc-react-datepicker-calendar.vc-datepicker-light .react-datepicker__day--selected{
    color: #fff;
    font-weight: bold;
    background-color: #422AFB;
}

/* Calendar dark theme */
.vc-react-datepicker-calendar.vc-datepicker-dark {
    background-color: #1F2733;
    border: 1px solid rgba(255, 255, 255, 0.24);
}

.vc-react-datepicker-calendar.vc-datepicker-dark .react-datepicker__header{
    background-color: #1A202C;
}

.vc-react-datepicker-calendar.vc-datepicker-dark .react-datepicker__current-month,
.vc-react-datepicker-calendar.vc-datepicker-dark .react-datepicker__day-name,
.vc-react-datepicker-calendar.vc-datepicker-dark .react-datepicker__day {
    color: #fff;
}

.vc-react-datepicker-calendar.vc-datepicker-dark .react-datepicker__day--disabled{
    color: rgba(255, 255, 255, 0.24);
}

.vc-react-datepicker-calendar.vc-datepicker-dark .react-datepicker__day--selected{
    color: #fff;
    font-weight: bold;
    background-color: #422AFB;
}

/* Input common theme */
.vc-react-datepicker-wrapper {
    width: 100%;
}

.vc-react-datepicker-input {
    width: 100%;
    border: none;
    height: 48px;
    background-color: transparent;
    border-radius: 6px;
    padding: 8px 12px;
    outline: none;
}

/* Input light theme */
.vc-react-datepicker-input.vc-datepicker-light {
    color: #2B3674;
    background-color: #F7FAFC;

}

/* Input dark theme */
.vc-react-datepicker-input.vc-datepicker-dark {
    color: #fff;
    background-color: #1F2733;
}

.vc-react-datepicker-input.vc-datepicker-dark.vc-datepicker-not-in-modal {
    background-color: #0b1437;
}

.vc-react-datepicker-input.vc-datepicker-dark.vc-datepicker-in-widget {
    background-color: transparent;
}

.vc-react-datepicker-input.vc-datepicker-light.vc-datepicker-not-in-modal {
    background-color: #EBF8FF;
}

.vc-react-datepicker-input.vc-datepicker-light.vc-datepicker-in-widget {
    background-color: transparent;
}

.react-datepicker__month-select,
.react-datepicker__year-select {
    width: 100%;
    padding: 5px 5px;
    border-radius: 6px;
    border: 1px solid;
    outline: none;
    font-family: "DM Sans", sans-serif;
    margin-top: 4px;
    margin-bottom: 4px;
}

.vc-react-datepicker-calendar.vc-datepicker-light .react-datepicker__month-select,
.vc-react-datepicker-calendar.vc-datepicker-light .react-datepicker__year-select {
    background-color: #F7FAFC;
    color: #2B3674;
    border-color: #aeaeae;
}

.vc-react-datepicker-calendar.vc-datepicker-dark .react-datepicker__month-select,
.vc-react-datepicker-calendar.vc-datepicker-dark .react-datepicker__year-select {
    background-color: #1F2733;
    color: #FFFFFF !important;
    border-color: rgba(255, 255, 255, 0.24);
}

.vc-react-datepicker-calendar.vc-datepicker-dark .react-datepicker__month-select option,
.vc-react-datepicker-calendar.vc-datepicker-dark .react-datepicker__year-select option {
    background-color: #1F2733;
    color: #FFFFFF;
}

.react-datepicker__month-select:hover,
.react-datepicker__year-select:hover {
    border-color: #63b3ed;
}

.react-datepicker__month-select:focus,
.react-datepicker__year-select:focus {
    box-shadow: 0 0 0 1px #63b3ed;
}

.react-datepicker__header {
    padding-top: 8px;
    padding-bottom: 8px;
}