.phone-container-tooltip  {
  width: 25px;
  padding-bottom: 2px;
}

.phone-container-tooltip .flag .arrow {
  display: none;
}

.phone-container-tooltip .selected-flag {
  padding: 0;
}