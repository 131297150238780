.react-tel-input .flag-dropdown.open {
    background-color: transparent;
    background: transparent;

    &:hover, :focus {
      background-color: transparent;
      background: transparent;
    }
}

.react-tel-input .flag-dropdown.open .selected-flag {
  background-color: transparent;
  background: transparent;

  &:hover, :focus {
    background-color: transparent;
    background: transparent;
  }
}

.react-tel-input .selected-flag:hover, .react-tel-input .selected-flag:focus {
  background-color: transparent;
  background: transparent;
}

.react-tel-input .phone-dropdown-dark .country:hover {
  background-color: transparent;
  background: #1A202C;
}

.react-tel-input .phone-dropdown-dark .country.highlight {
  background-color: transparent;
  background: #1A202C;
}

.react-tel-input .phone-dropdown, .phone-dropdown-dark {
  bottom: 0;
  font-family: 'DM Sans';
  font-weight: 500;
  font-size: 14px;
}
